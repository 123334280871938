import { setApiToken, UserModel, FontOption, HomeworkInfo, PupilHomework } from '@/edshed-common/api'
import { ServerEnv } from '@/edshed-common/servers/types'
import { State, Environment } from './types'

function setLocalStorageItem (key: string, value: any) {
  if (!window.localStorage) {
    return
  }
  window.localStorage.setItem(key, JSON.stringify(value))
}

function clearLocalStorageItem (key: string) {
  if (!window.localStorage) {
    return
  }
  window.localStorage.removeItem(key)
}

export default {
  TOGGLE_LOADING (state: State) {
    state.callingAPI = !state.callingAPI
  },
  TOGGLE_SEARCHING (state: State) {
    state.searching = (state.searching === '') ? 'loading' : ''
  },
  SET_USER (state: State, user: UserModel | null) {
    state.user = user
    setLocalStorageItem('user', user)

    if (user === null) {
      state.homeworks = null
      clearLocalStorageItem('homeworks')
    }
  },
  SET_TOKEN (state: State, token: string | null) {
    const expiry = new Date(Date.now() + (1000 * 60 * 60 * 24 * 90))
    console.log('EXPIRY: ' + expiry.toUTCString())
    if (process.env.NODE_ENV === 'development') {
      document.cookie = `_session-token_=${token};path=/;domain=localhost;expires=${expiry.toUTCString()};`
    } else {
      document.cookie = `_session-token_=${token};path=/;domain=.edshed.com;expires=${expiry.toUTCString()};`
    }
    state.token = token
    setLocalStorageItem('token', token!)
    setApiToken(token)
  },
  ADD_SESSION (state: State, session: string | null) {
    state.session = session
  },
  SET_MUSIC (state: State, on: boolean) {
    state.musicOn = on
    setLocalStorageItem('musicOn', on)
  },
  SET_SOUNDFX (state: State, on: boolean) {
    state.soundFXOn = on
    setLocalStorageItem('soundFXOn', on)
  },
  SET_LETTERNAMES (state: State, on: boolean) {
    state.letterNamesOn = on
    setLocalStorageItem('letterNamesOn', on)
  },
  SET_INVERTCALC (state: State, on: boolean) {
    state.invertCalcOn = on
    setLocalStorageItem('invertCalcOn', on)
  },
  SET_API_ENV (state: State, env: ServerEnv) {
    state.api_environment = env
  },
  SET_DISABLE_TIMERS (state: State, disable: boolean) {
    state.disableTimer = disable
    setLocalStorageItem('disableTimer', disable)
  },
  SET_FONT (state: State, fontPreference: FontOption) {
    state.fontPreference = fontPreference
    setLocalStorageItem('fontPreference', fontPreference)
  },
  SET_CAN_CHANGE_FONT (state: State, canChangeFont: boolean) {
    state.canChangeFont = canChangeFont
    setLocalStorageItem('canChangeFont', canChangeFont)
  },
  SET_HOMEWORKS (state: State, homework: PupilHomework[] | null) {
    state.homeworks = homework
    if (homework === null) {
      clearLocalStorageItem('homeworks')
    } else {
      setLocalStorageItem('homeworks', homework)
    }
  }
}
