import { ServerEnvInfo } from '@/edshed-common/servers'

// @ts-ignore
const serverInfo: ServerEnvInfo = process.env.SERVER_INFO

// eslint-disable-next-line no-console
console.log('Using server:', serverInfo.api)

export default {
  serverInfo,
  serverURI: serverInfo.api,
  authURI: serverInfo.auth,
  gameURI: serverInfo.game
}
