if (!String.fromCodePoint) {
  (function (stringFromCharCode) {
    const fromCodePoint = function (_) {
      const codeUnits = []; let codeLen = 0; let result = ''
      for (let index = 0, len = arguments.length; index !== len; ++index) {
        let codePoint = +arguments[index]
        // correctly handles all cases including `NaN`, `-Infinity`, `+Infinity`
        // The surrounding `!(...)` is required to correctly handle `NaN` cases
        // The (codePoint>>>0) === codePoint clause handles decimals and negatives
        if (!(codePoint < 0x10FFFF && (codePoint >>> 0) === codePoint)) { throw new RangeError('Invalid code point: ' + codePoint) }
        if (codePoint <= 0xFFFF) { // BMP code point
          codeLen = codeUnits.push(codePoint)
        } else { // Astral code point; split in surrogate halves
        // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
          codePoint -= 0x10000
          codeLen = codeUnits.push(
            (codePoint >> 10) + 0xD800, // highSurrogate
            (codePoint % 0x400) + 0xDC00 // lowSurrogate
          )
        }
        if (codeLen >= 0x3FFF) {
          result += stringFromCharCode.apply(null, codeUnits)
          codeUnits.length = 0
        }
      }
      return result + stringFromCharCode.apply(null, codeUnits)
    }
    try { // IE 8 only supports `Object.defineProperty` on DOM elements
      Object.defineProperty(String, 'fromCodePoint', {
        value: fromCodePoint, configurable: true, writable: true
      })
    } catch (e) {
      String.fromCodePoint = fromCodePoint
    }
  }(String.fromCharCode))
}
