import axios from 'axios'
import config from '../config'

export default {
  request (method, uri, data = null, token = null) {
    if (!method) {
      console.error('API function call requires method argument')
      return
    }

    if (!uri) {
      console.error('API function call requires uri argument')
      return
    }

    const url = config.serverURI + uri
    if (token != null) {
      axios.defaults.headers.common['X-Spellingshed-Token'] = token
    }
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    return axios({ method, url, data })
  }
}
