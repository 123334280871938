import { Howl } from 'howler'

// Sounds are not pre-loaded by default - to prevent loading the files if the user has either music or sound disabled
// In App.vue, the decision to load and autoplay the audio resources is made
// In SettingsModal.vue, the sounds are loaded when they are enabled
export default {
  install (Vue, options) {
    Vue.prototype.$sounds = {}

    Vue.prototype.$sounds.backgroundMusic = new Howl({
      src: ['/audio/Fearless Game Lp1.ogg', '/audio/Fearless Game Lp1.mp3'],
      loop: true,
      volume: 0.2,
      preload: false
    })

    Vue.prototype.$sounds.clickSound = new Howl({
      src: ['/audio/Click2.ogg', '/audio/Click2.mp3'],
      preload: false
    })

    Vue.prototype.$sounds.correctSound = new Howl({
      src: ['/audio/BoxComplete.ogg', '/audio/BoxComplete.mp3'],
      preload: false
    })

    Vue.prototype.$sounds.incorrectSound = new Howl({
      src: ['/audio/BoxFall.ogg', '/audio/BoxFall.mp3'],
      preload: false
    })

    Vue.prototype.$sounds.wellDoneSound = new Howl({
      src: ['/audio/MagicStars.ogg', '/audio/MagicStars.mp3'],
      preload: false
    })
  }
}
