import { ServerEnv } from '@/edshed-common/servers/types'
import { State } from './types'

const state: State = {
  callingAPI: false,
  searching: '',
  serverURI: process.env.SERVER || '',
  user: null as any,
  token: null,
  api_environment: (process.env.SERVER_ENV ?? 'production') as ServerEnv,
  session: null,
  musicOn: false,
  soundFXOn: true,
  letterNamesOn: false,
  invertCalcOn: false,
  disableTimer: false,
  fontPreference: 'sassoon',
  canChangeFont: false,
  homeworks: null
}

export default state
