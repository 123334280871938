import type { DictionaryLanguage } from '../../i18n'
import type { Avatar } from './avatar'
import type { ArrayElement, Bit, ISO8601Date, JsonString, Locale } from './common'
import type { DictionaryDefinition, DictionaryInfo } from './dictionary'
import type { Relationship } from './relationships'

export interface SpellingList {
  id: number
  ident: string
  s_code: string
  title: string
  owner_id: number
  update_timestamp: ISO8601Date
  creation_timestamp: ISO8601Date
  /**
   * @deprecated
   */
  locale: DictionaryLanguage
  dictionary_language: DictionaryLanguage // saved as locale in database
  scheme: 0 | 1
  scheme_variant: string | null
  stage: number
  stage_category: string | null
  list: number
  scheme_region: string | null
  is_phonics: 0 | 1
  list_word_count: number
  list_rating: number
  hidden: Bit
  fav: string
  owner: string
  rating: string | number
  words?: readonly SpellingListWord[]
  word_count?: number
  competence_score?: string
}

export interface EditSpellingListOptions {
  title: string
  owner_id: number
  hidden: Bit
  locale: DictionaryLanguage
  scheme: 0 | 1
  scheme_region: string | null
  scheme_variant: string | null
  stage: number
  stage_category: string | null
  list: number
}

export interface SpellingListWord {
  ident: string
  text: string
  sentences: string // legacy app support
  definitions: string // legacy app support
  phonics: string // legacy app support
  errors: string // legacy app support
  difficulty_index: number | null
  morphemes: string // legacy app support
  dictionary: DictionaryInfo
  list_id: number
}

export interface AddSpellingListOptions {
  title: string
  words: readonly WordToAdd[]
  locale?: DictionaryLanguage
  phonics?: 1 | 0
  scheme?: 1 | 0
  scheme_variant?: string
  scheme_region?: string
  stage?: number
  stage_category?: string
  list?: number
  ident?: string
  hidden?: Bit
}

export interface UpdateListOptions {
  title?: string
  locale?: DictionaryLanguage
  is_phonics?: Bit
}

export interface UpdateWordCompetenceRequest<T extends { text: string, dictionary_id: number | null, correct: 0 | 1 }> {
  words: readonly T[]
  difficulty: number
}

export interface UpdateCompetenceRequest {
  list_ident: string
  words: readonly GameplayWordToSave[]
  difficulty: number
}

export const ListAnswerCorrectValues = [0, 1] as const
export type ListAnswerCorrectValues = ArrayElement<typeof ListAnswerCorrectValues>

export interface UpdateCompetenceResponse {
  list_ident?: string
  old_score?: number
  new_score?: number
  error?: string
}

export interface WordToAdd {
  readonly text?: string
  readonly dictionary_id?: number
}

export interface BeekeeperWordToSave {
  text: string
  dictionary_id: number | null
  parent_dictionary_id: number | null
}

export interface GameplayWordToSave {
  text: string
  answer?: string
  correct: Bit
  dictionary_id: number | null
  parent_dictionary_id: number | null
  difficulty?: number
  adaptiveLevel?: number
}

export const PupilReportedListType = ['Inappropriate', 'Bullying', 'Other'] as const
export type PupilReportedListType = ArrayElement<typeof PupilReportedListType>

export const TeacherReportedListType = ['Inappropriate', 'Spelling mistake', 'Incorrect dictionary data', 'Other'] as const
export type TeacherReportedListType = ArrayElement<typeof TeacherReportedListType>

export type ReportedListType = PupilReportedListType | TeacherReportedListType

export interface ReportListRequestParams {
  description: string
  type: ReportedListType
}

export interface ReportedListToAdd {
  list_id: number
  description: string
  type: ReportedListType
}

export interface ReportedListWordToAdd {
  list_id: number
  list_word_id: number
  description: string
  type: ReportedListType
}

export interface ReportedListInfo {
  id: number
  type: ReportedListType
  description: string
  created: ISO8601Date
  user_id: number
  user: string
  list_id: number
  list_title: string
  list_ident: string
  ignored: boolean
  raised_by_pupil: boolean
  avatar?: JsonString<Avatar> // to be removed once Edshed is updated
  avatar_hash?: string | null
  content_creator_relationship?: Relationship
}

export interface ReportedListWordInfo {
  id: number
  type: ReportedListType
  description: string
  created: ISO8601Date
  user_id: number
  user: string
  list_id: number
  list_title: string
  list_ident: string
  ignored: boolean
  text: string
  list_word_id: number
  list_word_ident: string
  dictionary: DictionaryInfo
  avatar?: JsonString<Avatar> // to be removed once Edshed is updated
  content_creator_relationship?: Relationship
}

export type GetListResponse = {
  list: SpellingList
} | {
  error: 'List not found'
}

export interface UpdateWordOptions {
  listId: string
  wordId: string
  dictionary_id: number
}

export interface UpdateAdaptiveSpellingGameSessionRequest {
  score: number
  wordScore: number
  wordIndex: number
  wordToSave: GameplayWordToSave
}

export interface UpdateMiniDiagnosticGameSessionRequest extends UpdateAdaptiveSpellingGameSessionRequest { }

export interface UpdateTestModeGameSessionRequest {
  score: number
  completed: boolean
  wordScore: number
  wordIndex: number
  wordToSave: GameplayWordToSave
}

export interface ResumeOrCreateTestModeGameSessionRequest {
  assignmentId: number
}
export interface SearchListItem {
  ident: string
  title: string
  update_timestamp: ISO8601Date
  creation_timestamp: ISO8601Date
  locale: Locale
  scheme: Bit
  is_phonics: Bit
  list_word_count: number
  word_count: number
  list_rating: number
  rating: number
  username: string
  owner: string
  fav?: string
  s_code: string
}

export interface WordsSpelledReportRow {
  word: string
  answer_given: string
  correct: Bit
  timestamp: ISO8601Date
  root_dictionary_id: number
  root_definitions: JsonString<readonly DictionaryDefinition[]> | null
}

export interface WordsSpelledPerGroupReportRow extends WordsSpelledReportRow {
  user_id: number
  name: string
}

export interface ListCompetency {
  list_id: number
  user_id: number
  competence_score: number
}

export interface SpellingGameInfo {
  id: number
  timestamp: ISO8601Date
  difficulty: number
  score: number | null
  hive: Bit
  hive_id: number | null
  earnings: number
  is_test: boolean
  assignment_id: number | null
  list_title: string
  list_ident: string
  list_code: string
  percentage_score: number
  results: SpellingGameAnswer[]
}

export interface SpellingGameAnswer {
  game_play_id: number
  answer_given: string
  dictionary_id: number
  word: string | null
  correct: Bit
  definitions: readonly DictionaryDefinition[]
}

export interface SentencesGameInfo {
  id: number
  timestamp: ISO8601Date
  difficulty: number
  score: number | null
  hive: Bit
  hive_id: number | null
  earnings: number
  is_test: boolean
  assignment_id: number | null
  list_title: string
  list_ident: string
  list_code: string
  percentage_score: number
  results: readonly SentencesGameAnswer[]
}

export interface SentencesGameAnswer {
  game_play_id: number
  answer_given: string
  word: string | null
  correct: Bit
}

export interface BeekeeperGameInfo {
  id: number
  timestamp: ISO8601Date
  earnings: number
  list_title: string
  list_ident: string
  list_code: string
  words: string
}

export interface BuzzwordsGameInfo {
  id: number
  timestamp: ISO8601Date
  hard: Bit
  score: number
  words: string
  earnings: number
}

export interface SchemeWordForImport {
  text: string
  is_hfw: boolean
  is_cew: boolean
}

export interface SchemeWordListForImport {
  stage: number
  list: number
  title: string
  locale: DictionaryLanguage
  words: SchemeWordForImport[]
  scheme_variant: string | null
  scheme_region: string | null
  stage_category: string | null
}
