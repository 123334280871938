import { Route } from 'vue-router'
import axios from 'axios'
import { i18n } from '@/plugins/i18n'
import { locales } from '@/edshed-common/i18n/locale-map'
import store from '../store'

// import LoginMathsView from '@/components/LoginMaths'
// @ts-ignore
// const LoginWondeView = () => import('@/components/LoginWonde')
// @ts-ignore
// const LoginCleverView = () => import('@/components/LoginClever')
// @ts-ignore
const LogoutView = () => import('@/components/Logout')
// @ts-ignore
// const LogoutMathsView = () => import('@/components/LogoutMaths')
// @ts-ignore
const SpellingGameView = () => import('@/components/spelling/SpellingGame')
// @ts-ignore
const SquirrelScurryView = () => import('@/components/spelling/SquirrelScurry')
// @ts-ignore
const NumberGameView = () => import('@/components/NumberGame')
// @ts-ignore
const BeekeeperView = () => import('@/components/Beekeeper')
// @ts-ignore
const BuzzwordsView = () => import('@/components/Buzzwords')
// @ts-ignore
const LoadUser = () => import('@/components/LoadUser')
// @ts-ignore
const LoginView = () => import('@/components/Login')
// @ts-ignore
const QuizMenuView = () => import('@/components/QuizMenu')
// @ts-ignore
const QuizGameView = () => import('@/components/QuizGame')
// @ts-ignore
const LessonsWrapperView = () => import('@/components/LessonsWrapper')
// @ts-ignore
const Scrapbook = () => import('@/edshed-common/components/mathv2-components/Scrapbook')
// @ts-ignore
const AssignmentsView = () => import('@/components/Assignments')
// @ts-ignore
const ChallengesView = () => import('@/components/Challenges')
// @ts-ignore
const LeaguesView = () => import('@/components/Leagues')
// @ts-ignore
const SpellingMenuView = () => import('@/components/SpellingMenu')
// @ts-ignore
const MenuView = () => import('@/components/MainMenu')
// @ts-ignore
const NumberMenuView = () => import('@/components/NumberMenu')
// @ts-ignore
const LostView = () => import('@/components/Lost')
// @ts-ignore
const PhonicsMap = () => import('@/components/phonics/PhonicsMap')
// @ts-ignore
const PhonicsGame = () => import('@/components/phonics/PhonicsGame')
// @ts-ignore
const PhonicsGraphemeTester = () => import('@/components/phonics/PhonicsGraphemeTester')
// @ts-ignore
const PhonicsCharacterTester = () => import('@/components/phonics/PhonicsCharacterTester')
// @ts-ignore
const PhaserTest = () => import('@/components/phaser/PhaserTest')
// @ts-ignore
const GrammarGame = () => import('@/components/grammarcade/GrammarGame')
// @ts-ignore
const GrammarMenu = () => import('@/components/grammarcade/GrammarMenu')
// const HiveTester = () => import('@/components/HiveTester')

const SupportedLocales = locales.filter(l => l.user).map(l => l.code)

const routes = [
  {
    path: '/loaduser',
    name: 'LoadUser',
    component: LoadUser
  },
  // {
  //   path: 'loginwonde',
  //   name: 'LoginWonde',
  //   component: LoginWondeView
  // },
  // {
  //   path: 'loginclever',
  //   name: 'LoginClever',
  //   component: LoginCleverView
  // },
  {
    path: '/logout',
    component: LogoutView,
    name: 'Logout',
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    async beforeEnter (to: Route, from: Route, next: any) {
      let newLocale = 'en-gb'
      if (store.state.user) {
        newLocale = store.state.user.locale.replace('_', '-').toLowerCase()
      } else {
        try {
          const dd = await axios.get('https://geolocation-db.com/json/', { timeout: 1000 }) // https://geoip-db.com/json/
          const countryCode = dd.data.country_code
          console.log('COUNTRY CODE: ' + countryCode)

          if (countryCode === 'AU') {
            newLocale = 'en-au'
          } else if (countryCode === 'NZ') {
            newLocale = 'en-nz'
          } else if (countryCode === 'IE') {
            newLocale = 'en-ie'
          } else if (countryCode === 'US') {
            newLocale = 'en-us'
          }
        } catch (e) {
          console.log(e)
        }
      }
      // console.log('from')
      // console.log(from)
      // console.log('to')
      // console.log(to)
      return next({ path: '/' + newLocale, query: to.query })
    }
  },
  {
    path: '/:lang',
    component: {
      render: h => h('router-view')
    },
    beforeEnter (to: Route, from: Route, next: any) {
      console.log('beforeEnter')
      const lang = to.params.lang // <== This is lowercase!

      // Check the token as well as the user in case we have one and not other (stack overflow redirect bug)
      const hasToken = store.state.token && store.state.token !== 'null'

      console.log('beforeEnter: to.params.lang =', lang)

      if (!SupportedLocales.includes(lang)) {
        console.log('beforeEnter: bad URL locale', lang)

        if (store.state.user && hasToken) {
          const userLocale = store.state.user.locale.replace('_', '-').toLowerCase()

          if (userLocale !== lang) {
            console.log('beforeEnter: userLocale !== lang', userLocale, lang)
            return next({ path: '/' + userLocale + to.path, query: to.query })
          }
        } else {
          return next({ path: '/en-gb/login', query: to.query })
        }

        return next({ path: '/en-gb/' + lang, query: to.query })
      } else if (store.state.user && hasToken) {
        const userLocale = store.state.user.locale.replace('_', '-').toLowerCase()

        if (userLocale !== lang) {
          console.log('beforeEnter: userLocale !== lang', userLocale, lang)
          return next({ path: to.path.replace(lang, userLocale), query: to.query })
        }
      }

      if (lang && i18n.locale !== lang) {
        console.log('beforeEnter: setting lang =', lang)
        i18n.locale = lang
      } else if (!lang) {
        console.log('beforeEnter: setting lang = default (en-gb)')
        i18n.locale = 'en-gb'
      }

      next()
    },
    children: [

      {
        path: '/',
        name: 'MainMenu',
        component: MenuView,
        meta: { requiresAuth: true }
      },
      {
        path: 'spelling',
        name: 'SpellingMenu',
        component: SpellingMenuView
      },
      {
        path: 'number',
        name: 'NumberMenu',
        component: NumberMenuView,
        meta: { requiresAuth: true, space: true }
      },
      {
        path: 'numbergame',
        name: 'NumberGame',
        component: NumberGameView,
        meta: { requiresAuth: true, space: true }
      },
      {
        path: 'mtc',
        name: 'MTCGame',
        component: NumberGameView,
        meta: { requiresAuth: false, space: true, mtc: true }
      },
      {
        path: 'login',
        name: 'Login',
        component: LoginView
      },
      // {
      //   path: '/loginmaths',
      //   name: 'LoginMaths',
      //   component: LoginMathsView
      // },
      // {
      //   path: 'logoutmaths',
      //   component: LogoutMathsView,
      //   name: 'LogoutMaths',
      //   meta: { requiresAuth: true }
      // },
      {
        path: 'spellinggame',
        component: SpellingGameView,
        name: 'SpellingGame',
        props: true
      },
      {
        path: 'squirrelscurry',
        component: SquirrelScurryView,
        name: 'SquirrelScurry',
        props: true
      },
      {
        path: 'beekeeper',
        component: BeekeeperView,
        name: 'Beekeeper',
        meta: { requiresAuth: true }
      },
      {
        path: 'buzzwords',
        component: BuzzwordsView,
        name: 'Buzzwords',
        meta: { requiresAuth: true }
      },
      {
        path: 'quizmenu',
        component: QuizMenuView,
        name: 'QuizMenu',
        meta: { requiresAuth: true }
      },
      {
        path: 'quizgame',
        component: QuizGameView,
        name: 'QuizGame',
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'quizgame/:ident',
        component: QuizGameView,
        name: 'Play Quiz Game'
      },
      {
        path: 'lessons/:ident',
        component: LessonsWrapperView,
        name: 'Lesson',
        meta: { requiresAuth: true }
      },
      {
        path: 'scrapbook',
        component: Scrapbook,
        name: 'Scrapbook',
        meta: { requiresAuth: true }
      },
      {
        path: 'assignments',
        component: AssignmentsView,
        name: 'Assignments',
        meta: { requiresAuth: true }
      },
      {
        path: 'challenges',
        component: ChallengesView,
        name: 'Challenges',
        meta: { requiresAuth: true }
      },
      {
        path: 'leagues',
        component: LeaguesView,
        name: 'Leagues',
        meta: { requiresAuth: true }
      },
      {
        path: 'phonicsmenu',
        component: PhonicsMap,
        name: 'Phonics Menu',
        meta: { requiresAuth: true }
      },
      {
        path: 'phonicsgame',
        component: PhonicsGame,
        name: 'Phonics Game',
        meta: { requiresAuth: true }
      },
      {
        path: 'graphemetest',
        component: PhonicsGraphemeTester,
        name: 'Grapheme Test',
        meta: { requiresAuth: true }
      },
      {
        path: 'charactertest',
        component: PhonicsCharacterTester,
        name: 'Character Test',
        meta: { requiresAuth: true }
      },
      {
        path: 'phaser',
        component: PhaserTest,
        name: 'Phaser Test',
        meta: { requiresAuth: true }
      },
      // {
      //   path: 'hivetest',
      //   component: HiveTester,
      //   name: 'Hive Test',
      //   meta: { requiresAuth: true }
      // },
      {
        path: 'grammar',
        component: GrammarMenu,
        name: 'Grammar Menu',
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
          console.log('grammar menu')
          console.log(from)
          const fromGame = from.name === 'Grammar Game'
          to.params.navigatedFromGame = fromGame
          next()
        }
      },
      {
        path: 'grammar/game',
        component: GrammarGame,
        name: 'Grammar Game',
        meta: { requiresAuth: false }
      },
      {
        path: '*',
        component: LostView,
        name: '404'
      }
    ]
  }
]

export default routes
