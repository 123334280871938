import { Locale, PupilHomework, SchoolSubscription } from '@/edshed-common/api'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { localeToCurriculumLocale, spellingEducationalRegionFromLocale } from '@/edshed-common/utils'
import { SpellingEducationalRegion } from '@/edshed-common/i18n'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import { State } from './types'

Vue.use(Vuex)

const getters = {
  isSuperUser: (state: State) => {
    if (!state.user) {
      return false
    }
    return !!state.user.superuser
  },
  isTester: (state: State) => {
    if (!state.user) {
      return false
    }
    return !!state.user.tester
  },
  isDictionaryEditor: (state: State) => {
    if (!state.user) {
      return false
    }
    return !!state.user.dictionary_editor
  },
  isGrammarEditor: (state: State) => {
    if (!state.user) {
      return false
    }
    return !!state.user.dictionary_editor
    // return !!state.user.grammr_editor
  },
  isPupil: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.teacher) {
      return true
    }
    return false
  },
  hasSpelling: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }

    return !!state.user.permissions.find(p => p === 'spelling')
  },
  hasPhonics: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }

    return !!state.user.permissions.find(p => p === 'phonics')
  },
  hasNumber: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }

    return !!state.user.permissions.find(p => p === 'number')
  },
  hasLiteracy: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user!.school) {
      return false
    }

    return state.user.has_literacy === true
  },
  hasPremiumSpelling: (state: State) => {
    return getters.hasSpelling(state) && state.user?.spelling_access_level !== 'freemium'
  },
  hasPremiumNumber: (state: State) => {
    return getters.hasNumber(state) && state.user?.number_access_level !== 'freemium'
  },
  hasPremiumPhonics: (state: State) => {
    return getters.hasPhonics(state) && state.user?.phonics_access_level !== 'freemium'
  },
  hasGrammarArcade: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user!.school) {
      return false
    }
    if (state.user.school.id === 62286) { // Maggie's test school for US testing
      return true
    }
    if (spellingEducationalRegionFromLocale(state.user.school.locale) === 'en_US') {
      return false
    }
    if (state.user.spelling_access_level === 'freemium') {
      return false
    }
    return !!state.user.permissions.find(p => p === 'spelling')
  },
  isPremium: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }

    if (getters.hasPremiumSpelling(state) || getters.hasPremiumNumber(state) || getters.hasPremiumPhonics(state)) {
      return true
    }
  },
  isInEdshedSchool: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    return state.user.school.id === 8
  },
  isInBewdleySchool: (state: State) => {
    // often bewdley is used as a test bed
    // We ought to be using real flags for testing scenarios
    // but sometimes for quick testing turnaround it's simpler to have bewdley's school as a flag
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    return state.user.school.id === 1665
  },
  hasLimitLists: (state: State) => {
    if (!state.user) {
      return false
    }

    const hws = state.homeworks ?? []
    let hideOtherLists = false
    for (let i = 0; i < hws.length; i++) {
      if (hws[i].hide_other_lists === 1) {
        hideOtherLists = true
      }
    }
    console.log('hide: ' + hideOtherLists)
    return hideOtherLists
  },
  spellingAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => hw.type === 'spelling' || hw.subtype === 'spelling' || hw.subtype === 'spag').length
  },
  grammarAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => hw.type === 'grammar').length
  },
  phonicsAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => hw.type === 'phonics' || hw.subtype === 'phonics-book').length
  },
  numberAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => hw.type === 'number' || hw.subtype === 'mathshed').length
  },
  quizAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => (hw.type === 'quiz' || hw.type === 'lesson') && hw.subtype === null).length
  },
  quizTypeAssignmentCount: (state: State) => {
    if (!state.user) {
      return false
    }

    return state.homeworks?.filter(hw => hw.type === 'quiz' || hw.type === 'lesson').length
  },
  disableTimer: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    const schoolSettings = state.user.school.settings
    const userSettings = state.user.settings

    return userSettings?.disableTimer ?? !!schoolSettings?.disableTimer ?? false
  },
  userChosenFont: (state: State) => {
    switch (state.fontPreference) {
      case 'dyslexic':
        return 'OpenDyslexicAltaRegular'
      case 'sassoon':
        return 'Sassoon'
      case 'sassoonCurly':
        return 'SassoonCurly'
      case 'sassoonUs':
        return 'SassoonUS'
      case 'sassoonZa':
        return 'SassoonZA'
      case 'muli':
        return 'Muli'
      default:
        switch (state.user?.locale) {
          case 'en_US':
            return 'SassoonUS'
          case 'en_ZA':
            return 'SassoonZA'
          default:
            return 'Sassoon'
        }
    }
  },
  // Use this to limit features to the product regions (GB, US, ZA)
  // DO NOT use this for region pricing
  // DO NOT use this for filtering word lists
  // DO NOT use this to auto-set a locale
  curriculumLocale: (state: State): Locale => {
    if (!state.user) {
      return 'en_GB'
    }
    if (!state.user.school || !state.user.school.locale) {
      return state.user.locale || 'en_GB'
    }
    return localeToCurriculumLocale(state.user.school.locale)
  },

  homeworks: (state: State): PupilHomework[] => {
    return state.homeworks ?? []
  },
  spellingSchemeRegion: (state: State): SpellingEducationalRegion => {
    if (!state.user || !state.user.school || !state.user.school.locale) {
      return 'en_GB'
    }
    return spellingEducationalRegionFromLocale(state.user.school.locale)
  }

}

type Getters = typeof getters

export type _Getters = {
  [P in keyof Getters]: Getters[P] extends (s: State) => infer R ? R : never
}

/** This works by removing the `getters` and `commit` property and replacing them with statically typed versions */
export type StoreType = Omit<Store<State>, 'getters' | 'commit'> & {
  getters: _Getters,
  commit: <TMutation extends keyof typeof mutations> (
    mutation: TMutation,
    payload?: typeof mutations[TMutation] extends (s: State, p: infer P) => void ? P : never
  ) => void
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
}) as StoreType
