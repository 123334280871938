<template>
  <div id="app" :style="{'--user-chosen-font': userChosenFont}">
    <router-view />
  </div>
</template>

<script>
// import { Howl, Howler } from 'howler'
import api from '@/api'
import StylingMixin from '@/mixins/Helpers.ts'

export default {
  name: 'App',
  mixins: [StylingMixin],
  data () {
    return {
      section: 'Head'
    }
  },
  computed: {
    userChosenFont () {
      return `"${this.$store.getters.userChosenFont}"`
    },
    autoPlayMusic () {
      if (this.$store.state.user === null) { return false }
      if (!this.$store.state.musicOn) { return false }
      if (!window || !window.location) { return false }
      if (window.location.href.includes('grammar')) { return false }
      return true
    }
  },
  
  mounted () {
    if (this.autoPlayMusic) {
      this.$sounds.backgroundMusic.load()
      if (!this.$sounds.backgroundMusic.playing()) {
        this.$sounds.backgroundMusic.play()
      }
    }
    if (this.$store.state.soundFXOn) {
      this.$sounds.clickSound.load()
      this.$sounds.correctSound.load()
      this.$sounds.incorrectSound.load()
      this.$sounds.wellDoneSound.load()
    }
  },
}
</script>

<style lang="scss">
// Used to prevent page scroll and bounce on devices when playing games.
.fullscreen-edshed-game{
  touch-action: none!important;
  *{
    touch-action:none !important;
  }
}
@import "~bulma/sass/utilities/_all";

// Import common variables
@import '@/edshed-common/styles/variables';

$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "link": ($link, $link-invert),
    "number": ($number, $number-invert),
    "quiz": ($quiz, $quiz-invert),
    "phonics": ($phonics, $phonics-invert),
    "combined": ($combined, $combined-invert)
);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "link": ($link, $link-invert),
    "number": ($number, $number-invert),
    "quiz": ($quiz, $quiz-invert),
    "phonics": ($phonics, $phonics-invert),
    "combined": ($combined, $combined-invert)
);

@import "~bulma";
@import '~buefy/src/scss/buefy';

@import '~@/edshed-common/styles/fonts';
@import './scss/fonts';
@import './scss/base';

@import '~@/edshed-common/styles/themes/themes';
</style>
