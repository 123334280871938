// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@/polyfills/String.fromCodePoint'
import Vue from 'vue'
import Es6Promise from 'es6-promise'
import 'es6-symbol/implement'
import VueRouter from 'vue-router'
import { sync } from 'vuex-router-sync'
import Buefy from 'buefy'
import { i18n } from '@/plugins/i18n'
import config from '@/config'
import Helpers from '@/plugins/Helpers'
import store from '@/store'
import routes from '@/router/routes'
import { initApi, setApiToken, Api, FontOption, PupilHomework } from '@/edshed-common/api'
import AppView from '@/App.vue'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import 'web-animations-js'

import '@/edshed-common/directives'

Es6Promise.polyfill()

// Raven
//   .config('https://6cda799cbb1241bbaf87702c5a6719df@sentry.io/291835')
//   .addPlugin(RavenVue, Vue)
//   .install()
// Vue.use(VeeValidate)

Vue.use(VueMeta)
Vue.use(Helpers)
Vue.use(VueGtag, {
  config: { id: 'G-7G89QRFZXH' }
})

Vue.use(Buefy, {
  defaultIconPack: 'fa'
})

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
initApi(config.serverURI, () => {
  const base = config.authURI
  const ret = window.location.origin + window.location.search

  const edshedRedirect = base + 'accesstoken?return_url=' + encodeURIComponent(ret)
  router.push(`/logout?push_to=${edshedRedirect}`)
}, (env) => {
  store.commit('SET_API_ENV', env)
}, 'web-game', process.env.PACKAGE_VERSION ?? '')

const DevHostSuffix = '.localhost.edshed.xyz'

router.beforeEach(async (to, from, next) => {
  if (!store.state.user && location.hostname.endsWith(DevHostSuffix)) {
    const username = location.hostname.replace(DevHostSuffix, '')
    console.log('Doing magical dev login...', username)

    const devToken = 'devtoken-' + username
    setApiToken(devToken)

    const user = await Api.getCurrentUser()
    store.commit('SET_USER', user)
    store.commit('SET_TOKEN', user.token)

    return next()
  }

  const tokenCookie = getCookie('_session-token_')
  if (to.name !== 'Logout' && tokenCookie === 'null' && router.app.$store.state.token && router.app.$store.state.token !== tokenCookie) {
    console.log('FORCE LOGOUT')
    return next({ name: 'Logout' })
  }
  if (to.name !== 'Logout' && to.name !== 'LoadUser' && router.app.$store.state.token && router.app.$store.state.token !== 'null' && (!router.app.$store.state.user || router.app.$store.state.token !== router.app.$store.state.user.token)) {
    console.log('LOAD USER')
    return next({ name: 'LoadUser', params: { route: to.path }, query: to.query } as any)
  }

  if (to.matched.some(record => record.meta.requiresAuth) && (!router.app.$store.state.token || router.app.$store.state.token === 'null')) {
    console.log('USER REQUIRED -> ROUTING')
    next({
      name: 'Login',
      query: to.query
    })
  } else {
    next()
  }
})

// window.Event = new Vue()

/* eslint-disable no-new */
sync(store, router)

function getCookie (name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) {
    return match[2]
  }
}

const tokenCookie = getCookie('_session-token_')

// Check local storage to handle refreshes
if (window.localStorage) {
  let localStorageToken = window.localStorage.getItem('token') || ''
  let localToken = localStorageToken.replace(/"/g, '')
  let refreshSession = false
  if (tokenCookie) {
    if (tokenCookie !== localToken) {
      // reset session!
      localToken = tokenCookie
      refreshSession = true
    }
  }
  store.commit('SET_TOKEN', localToken)

  const localUserString = window.localStorage.getItem('user') || 'null'
  const localUser = JSON.parse(localUserString)
  if (localUser && store.state.user !== localUser) {
    // console.log('setting user from storage. Token: ' + window.localStorage.getItem('token'))
    if (!refreshSession) {
      store.commit('SET_USER', localUser)
    }
  }

  const homeworks = window.localStorage.getItem('homeworks')
  if (homeworks != null) {
    store.commit('SET_HOMEWORKS', JSON.parse(homeworks))
  }
  const musicOn = window.localStorage.getItem('musicOn')
  if (musicOn != null) {
    store.commit('SET_MUSIC', JSON.parse(musicOn))
  }
  const soundFXOn = window.localStorage.getItem('soundFXOn')
  if (soundFXOn != null) {
    store.commit('SET_SOUNDFX', JSON.parse(soundFXOn))
  }
  const letterNamesOn = window.localStorage.getItem('letterNamesOn')
  if (letterNamesOn != null) {
    store.commit('SET_LETTERNAMES', JSON.parse(letterNamesOn))
  }
  const invertCalcOn = window.localStorage.getItem('invertCalcOn')
  if (invertCalcOn != null) {
    store.commit('SET_INVERTCALC', JSON.parse(invertCalcOn))
  }
  const fontPreference = window.localStorage.getItem('fontPreference') as FontOption | null
  if (fontPreference != null && FontOption.includes(fontPreference)) {
    store.commit('SET_FONT', fontPreference)
  }
  const canChangeFont = window.localStorage.getItem('canChangeFont')
  if (canChangeFont != null) {
    store.commit('SET_CAN_CHANGE_FONT', JSON.parse(canChangeFont))
  }
}

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(AppView)
})
