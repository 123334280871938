import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  'en-gb': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County',
    Maths: 'Maths',
    SchoolDistricts: 'Trusts of groups of schools who want to be able to subscribe for several schools.',
    Favourites: 'Favourites',
    practise: 'practise',
    Practise: 'Practise'
  },
  'en-us': {
    hello: 'hello world',
    organisation: 'organization',
    Organisation: 'Organization',
    district: 'district',
    District: 'District',
    pupil: 'student',
    Pupil: 'Student',
    pupils: 'students',
    Pupils: 'Students',
    licences: 'licenses',
    scheme: 'curriculum',
    Postcode: 'Zip',
    County: 'State',
    Maths: 'Math',
    SchoolDistricts: 'School Districts who want to subscribe for more than one school.',
    Favourites: 'Favorites',
    practise: 'practice',
    Practise: 'Practice'
  },
  'en-ie': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    Favourites: 'Favourites',
    practise: 'practise',
    Practise: 'Practise'
  },
  'en-au': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    Favourites: 'Favourites',
    practise: 'practise',
    Practise: 'Practise'
  },
  'en-nz': {
    hello: 'hello world',
    organisation: 'organisation',
    Organisation: 'Organisation',
    district: 'group',
    District: 'Group',
    pupil: 'pupil',
    Pupil: 'Pupil',
    pupils: 'pupils',
    Pupils: 'Pupils',
    licences: 'licences',
    scheme: 'scheme',
    Postcode: 'Postcode',
    County: 'County / Region',
    Maths: 'Maths',
    SchoolDistricts: 'School Districts / trusts.',
    Favourites: 'Favourites',
    practise: 'practise',
    Practise: 'Practise'
  }
}

export const i18n = new VueI18n({
  locale: 'en-gb', // set locale
  fallbackLocale: 'en-gb',
  messages // set locale messages
})
