import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { Howl, Howler } from 'howler'
import api from '@/api'
import { GameTheme } from '@/edshed-common/api'

@Component
export default class Styling extends Vue {
  setTheme (th: GameTheme | 'default') {
    const htmlElement = document.documentElement
    if (th === 'spelling') {
      htmlElement.setAttribute('theme', 'spelling')
      if (this.deviceTimeIsNightTime()) {
        htmlElement.classList.add('theme-is-night')
      } else {
        htmlElement.classList.remove('theme-is-night')
      }
    } else if (th === 'spelling-football') {
      htmlElement.setAttribute('theme', 'spelling-football')
    } else if (th === 'spelling-horses') {
      htmlElement.setAttribute('theme', 'spelling-horses')
    } else if (th === 'spelling-van') {
      htmlElement.setAttribute('theme', 'spelling-van')
    } else if (th === 'number') {
      htmlElement.setAttribute('theme', 'number')
    } else if (th === 'quiz') {
      htmlElement.setAttribute('theme', 'quiz')
    } else if (th === 'phonics') {
      htmlElement.setAttribute('theme', 'phonics')
    } else {
      htmlElement.setAttribute('theme', 'default')
    }
  }

  i18nLocaleFormat (str: string): 'en_GB' | 'en_US' {
    const split = str.split('-')
    if (split[1]) { split[1] = split[1].toUpperCase() }
    return split.join('_') as 'en_GB' | 'en_US'
  }

  shuffle (array: any[]) {
    let counter = array.length
    while (counter > 0) {
      const index = Math.floor(Math.random() * counter)
      counter--
      const temp = array[counter]
      array[counter] = array[index]
      array[index] = temp
    }
    return array
  }

  tick () {
    if (this.$store.state.token) {
      console.log('THE TOKEN: ' + this.$store.state.token)
      api.request('get', 'tick', null, this.$store.state.token)
        .then((response) => {
        // var data = response.data
          console.log('Session refreshed')
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            // this.backgroundMusic.stop()
            if (this.$router.currentRoute.name !== 'Logout') {
              this.$router.push({ name: 'Logout' })
            }
          }
        })
    }
  }

  deviceTimeIsNightTime () {
    const hour = new Date().getHours()

    return (hour > 22 || hour < 5)
  }

  /**
   * Checks if the device date is April 1st before 12 noon
   *
   * Use this to toggle April Fools gags
   *
   * @returns bool
   */
  isAprilFoolsDay () {
    const date = new Date()
    const month = date.getMonth()
    const day = date.getDate()
    const hour = date.getHours()

    return month === 3 && day === 1 && hour < 12
    // return true
  }

  /**
 * disables browser translation features from automatically trasnlating text in a given element
 *
 * @param cssSelector CSS selector of parent component to disable translation extensions
 */
  disableTranslate (cssSelector: string) {
    const elem = document.querySelector(cssSelector) as HTMLElement
    if (elem && !!elem.setAttribute) {
      elem.setAttribute('translate', 'no')
    } else {
      console.warn(`Given selector does not exist on page ${cssSelector}`)
    }
  }
}
